import React, { FC } from 'react';

import { useContent } from 'providers/ContentProvider/ContentProvider';
import { GallerySwiper } from 'components/Molecules/GallerySwiper/GallerySwiper';
import { SideNav } from 'components/Molecules/SideNav/SideNav';
import { Text } from 'components/Atoms/Text/Text';
import { CTA } from 'components/Atoms/CTA/CTA';
import { useTranslations } from 'hooks/useTranslations';
import { getCurrentNavItem } from 'helpers';
import { Wrapper, Button, Svg } from './Category.style';
import { goBackVariants } from './variants';

export const Category: FC = () => {
  const { categoriesPageData } = useContent();
  const { menu, back } = useTranslations();

  const [{ path }] = getCurrentNavItem({ menu, elements: [2] });

  const sideNavData = {
    label: categoriesPageData.title,
    path: categoriesPageData.slug,
  };

  return (
    <>
      <Wrapper>
        <CTA to={path}>
          <Button variants={goBackVariants}>
            <Svg
              width="30"
              height="10"
              viewBox="0 0 448 224"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M80 16L16 80L80 144"
                stroke="#F8F8F8"
                strokeWidth="32"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M32 80H326C384.76 80 432 129.33 432 188V208"
                stroke="#F8F8F8"
                strokeWidth="32"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </Svg>
            <Text variant="normal">{back}</Text>
          </Button>
        </CTA>
        <GallerySwiper />
      </Wrapper>
      <SideNav mainItem={sideNavData} withSubLink={false} />
    </>
  );
};
