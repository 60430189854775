import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Swiper } from 'components/Organisms/Swiper/Swiper';

export const StyledSwiper = styled(Swiper)`
  height: 100%;
  width: 100%;
`;

export const Wrapper = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: grab;
  display: flex;
  flex-direction: column-reverse;
  align-content: flex-start;
  align-self: flex-start;
  flex-wrap: wrap;
  padding: 0 0 0 10px;

  ${({ theme }) => theme.mq.tablet} {
    align-self: center;
  }
`;

export const Image = styled(GatsbyImage)`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const BlockGallery = styled(motion.div)<{ isEven: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  ${({ isEven }) =>
    isEven &&
    css`
      flex-direction: column-reverse;
    `}
`;

export const Item = styled(motion.div)`
  position: relative;
  width: 100%;
  flex-basis: 40%;
  margin-bottom: 1rem;

  :nth-child(odd) {
    flex-basis: 60%;
  }
`;

export const BigImageWrapper = styled(motion.div)`
  max-width: 90%;
  max-height: 90%;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;

  ${({ theme }) => theme.mq.tablet} {
    max-width: 80%;
    max-height: 80%;
  }
`;
