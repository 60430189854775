import React, { FC, useState } from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';

import { useContent } from 'providers/ContentProvider/ContentProvider';
import { Modal } from 'components/Molecules/Modal/Modal';
import { chunkArray } from 'helpers';
import type { ID } from 'types';
import {
  StyledSwiper,
  Wrapper,
  Image,
  BlockGallery,
  Item,
  BigImageWrapper,
} from './GallerySwiper.style';
import { childVariants, modalVariants } from './variants';

const initialValue = {
  imageSrc: null,
  imageAlt: '',
  imageId: null,
};

export const GallerySwiper: FC = () => {
  const { categoriesPageData } = useContent();
  const [{ imageSrc, imageAlt, imageId }, setSelectedImage] = useState(initialValue);

  const getCurrentPhoto = async (itemId: ID) => {
    const { gatsbyImageData, alt, id } = categoriesPageData.gallery.find(
      ({ id }: { id: ID }) => id === itemId,
    );

    setSelectedImage({
      imageSrc: gatsbyImageData,
      imageAlt: alt,
      imageId: id,
    });
    Modal;
  };

  const handleCloseModal = () => {
    setSelectedImage(initialValue);
  };

  return (
    <AnimateSharedLayout type="crossfade">
      <Modal isOpen={!!imageId} onClose={handleCloseModal}>
        <BigImageWrapper
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={handleCloseModal}
          layoutId={`image-${imageId}`}
        >
          {imageSrc && <Image image={imageSrc} alt={imageAlt} objectFit="contain" />}
        </BigImageWrapper>
      </Modal>

      <Wrapper>
        <StyledSwiper
          spaceBetween={10}
          breakpoints={{
            320: {
              slidesPerView: 1.6,
              spaceBetween: 10,
            },
            375: {
              slidesPerView: 1.6,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 3.2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2.2,
              spaceBetween: 10,
            },
            1020: {
              slidesPerView: 3.6,
              spaceBetween: 10,
            },
            1150: {
              slidesPerView: 5.5,
              spaceBetween: 10,
            },
          }}
        >
          {chunkArray(categoriesPageData.gallery, 2).map((item, index) => (
            <BlockGallery
              key={index}
              isEven={index % 2 === 0 && item.length > 1}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={childVariants}
              custom={index}
            >
              {item.map(({ id, gatsbyImageData, alt }) => (
                <Item key={id} onClick={() => getCurrentPhoto(id)}>
                  <motion.div layoutId={`image-${id}`}>
                    <Image image={gatsbyImageData} alt={alt} />
                  </motion.div>
                </Item>
              ))}
            </BlockGallery>
          ))}
        </StyledSwiper>
      </Wrapper>
    </AnimateSharedLayout>
  );
};
