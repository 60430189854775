export const childVariants = {
  hidden: {
    opacity: 0,
    x: '20%',
  },

  visible: (delay: number) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: delay * 0.15,
    },
  }),

  exit: (delay: number) => ({
    opacity: 0,
    x: '-20%',
    transition: {
      duration: 0.3,
      delay: delay * 0.15,
    },
  }),
};

export const modalVariants = {
  hidden: {
    opacity: 0,
    scale: 0.4,
  },

  visible: {
    opacity: 1,
    scale: 1,
  },
};
