import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.section`
  max-width: ${({ theme }) => theme.layout.maxContainerWidth};
  height: 100%;
  width: 100%;
  max-height: 80%;
  margin: 2rem auto 0;
  position: relative;
  padding-left: 8rem;

  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 5rem;
    padding-left: 12rem;
  }
`;

export const Button = styled(motion.span)`
  position: absolute;
  top: -4rem;
  left: 5rem;
  z-index: ${({ theme }) => theme.zIndex.level2};
  display: flex;
  align-items: center;
`;

export const Svg = styled.svg`
  margin-bottom: 4px;
  margin-right: 2px;
`;
