import React, { FC } from 'react';
import { AnimatePresence } from 'framer-motion';

import { Wrapper, PageOverlay } from './Modal.styles';
import { fadeIn } from './variants';

interface ModalProps {
  onClose: () => void;
  withOverlay?: boolean;
  isOpen?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  withOverlay = true,
  onClose,
  isOpen = false,
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <Wrapper>
          {withOverlay && (
            <PageOverlay
              variants={fadeIn}
              initial="hidden"
              animate="visible"
              exit="hidden"
              onClick={onClose}
            />
          )}
          {children}
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
