import { graphql } from 'gatsby';
import React from 'react';

import { Category } from 'components/Organisms/Category/Category';
import { ContentProvider } from 'providers/ContentProvider/ContentProvider';
import { SEO } from 'components/Organisms/SEO/SEO';

import type { CategoriesPageData } from 'types';

interface CategoryTemplateProps {
  data: CategoriesPageData;
}

const CategoryTemplate: React.FC<CategoryTemplateProps> = ({ data }) => {
  const { seo, locale, metakeywords } = data.categoriesPageData;

  return (
    <>
      <SEO seo={seo} lang={locale} keywords={metakeywords} />
      <ContentProvider data={data}>
        <Category />
      </ContentProvider>
    </>
  );
};

export default CategoryTemplate;

export const query = graphql`
  query CATEGORY_PAGE_QUERY($locale: String!, $id: String!) {
    socialIcons: allDatoCmsSocial(filter: { locale: { eq: $locale } }) {
      nodes {
        ...SocialIcon
      }
    }
    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }, sort: { fields: order }) {
      nodes {
        ...CategoryElement
      }
    }
    categoriesPageData: datoCmsCategory(locale: { eq: $locale }, id: { eq: $id }) {
      locale
      slug
      metakeywords
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ...CategoryGallery
    }
  }
`;
